// import Vue from 'vue'
import { Component } from "vue-property-decorator";
import api from "@/services/api";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Ruolo } from "@/models/ruolo";
import permessi from "@/config/permessi";

const _ENDPOINT = api.endpoint.RUOLI.RUOLI;

@Component({})
export default class ruoliPage extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Anagrafica.RUOLI]; }
	showEditModal = false;
	editItem: Ruolo = new Ruolo();
	currentItem: any = {};

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, editHide: true },
			{ field: "codice", title: this.$i18n.t("generico.codice"), headerCell: "myHeaderCellTemplate", editDisabled: !this.isNewItem, export: true },
			{ field: "descrizione", title: this.$i18n.t("generico.descrizione"), headerCell: "myHeaderCellTemplate", export: true },
			//{ field: "superAdmin", title: this.$i18n.t("generico.superAdmin"), disabled: true, width: 200, type: "boolean", cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", filterType: "boolean", export: true },
			{ field: "backOffice", title: this.$i18n.t("generico.backOffice"), disabled: true, width: 150, type: "boolean", cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", filterType: "boolean", export: true },
			{ field: "disponibileAssociazioneWeb", title: this.$i18n.t("generico.associabileDaWeb"), disabled: true, width: 150, type: "boolean", cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", filterType: "boolean", export: false },
			{ field: "fisso", title: this.$i18n.t("generico.fisso"), disabled: true, width: 200, type: "boolean", cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", filterType: "boolean", export: false },

			{ field: "_actions", title: " ", width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate", export: true },
		];
	}

	created() {
		var self = this;


		// Promise.all([p])
		// 	.then(responses => {
		self.getData();
		// })
		// .catch(err => {
		// 	console.log(err);
		// });
	}

	mounted() { }

	addNewRow() {
		this.onEdit(new Ruolo());
	}

	exportExcel() {
		this.exportGridExcel(_ENDPOINT);
	}

	getData() {
		var self = this;
		this.getGridData(_ENDPOINT);
	}
	onDelete(item: Ruolo) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<strong>${item.codice}</strong><h5>${item.descrizione}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.tc("generico.ruolo_i", 1)]).toString();
		self.deleteGridRow(_ENDPOINT, item.itemID, title, [message]);
	}
	onEdit(item: Ruolo) {
		var self = this;

		self.editItem = Object.assign(new Ruolo(), item);
		self.editSubTitle = item.codice;
		self.editTitle = item.descrizione;
		self.showEditModal = true;
	}
	editTitle: string = null;
	editSubTitle: string = null;
	get isNewItem(): boolean {
		var self = this;
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.tc("generico.ruolo_i", 1) }).toString();
		self.setGridRow<Ruolo>(_ENDPOINT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false
		});
	}

	// onSuperAdminToggle(itemID: number) {
	// 	var self = this;
	// 	var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
	// 	var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.tc("generico.ruolo_i",1) }).toString();
	// 	api.setSuperAdminToggle(itemID).then((res) =>{
	// 		self.$bvToast.toast(message, {
	// 					variant: "success",
	// 					title: title,
	// 					solid: true
	// 				});
	// 		this.getData()});

	// }
	aggiungiRimuoviPermesso(item: any) {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.tc("generico.ruolo_i", 1) }).toString();
		api.aggiungiRimuoviPermesso(item).then((res) => {
			self.$bvToast.toast(message, {
				variant: "success",
				title: title,
				solid: true
			});
			// this.getData()
		});

	}
}
